import { toast } from "react-toastify"
const showErrorToast = (error) => {
  if (error && error.response && error.response.data && error.response.data.error) {
    const errorObj = error.response.data.error
    for (const key in errorObj) {
      if (Array.isArray(errorObj[key])) {
        toast.error(errorObj[key].join(','))
      } else {
        toast.error(errorObj[key])
      }
    }
  } else if (typeof error === 'string') {
    toast.error(error);
  }
  else if (error && error.common) {
    toast.error(error.common);
  } else {
    toast.error('Opps! Something went werong. Please try again')
  }
}
export default showErrorToast