import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import useLogin from "../../../hooks/services/login/useLogin";
import { useStore } from "../../../store";
import { useAdminStore } from "../../../store/admin";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";

const initialValues = {
  email: "",
  password: "",
  // captcha_token: "",
};

export default function Login() {
  const recaptchaRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const { makeAuthorized } = useStore();
  const { mutateAsync } = useLogin();
  const { getAdmin } = useAdminStore();
  useEffect(() => {
    return () => {
      setLoading({});
    };
  }, []);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 characters.")
      .max(50, "Maximum 50 characters.")
      .required(),
    password: Yup.string()
      .min(3, "Minimum 3 characters.")
      .max(50, "Maximum 50 characters.")
      .required(),
    // captcha_token: Yup.string(),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      // const captchaToken = await recaptchaRef.current.executeAsync();
      // values.captcha_token = captchaToken;
      try {
        enableLoading();
        const data = await mutateAsync(values);
        disableLoading();
        localStorage.setItem("accessToken", data.access_token);
        if (data.access_token) {
          makeAuthorized(data.access_token);
          getAdmin();
        }
      } catch (error) {
        toast.error(error?.response?.data?.error?.common);
        Object.keys(error?.response?.data.error).forEach((item) => {
          error?.response?.data?.error[item]?.forEach((message) => {
            toast.error(message);
          });
        });
      } finally {
        disableLoading();
      }
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            autoComplete="on"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <ReCAPTCHA
            className="recaptcha"
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            size="invisible"
            onChange={(token) => formik.setFieldValue("captcha_token", token)}
          />
        </div>

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}
