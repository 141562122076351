import _ from "lodash";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Eye } from 'phosphor-react';
/* eslint-disable no-useless-escape */
export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export function getLabelCssClasses(status) {
  return `label label-lg label-light-${status === "active"
    ? "primary"
    : status === "pending"
      ? "success"
      : status === "draft"
        ? "dark"
        : status === "in-trial"
          ? "success"
          : status === "non-renewing"
            ? "dark"
            : status === "paused"
              ? "warning"
              : status === "cancelled"
                ? "danger"
                : status === "future"
                  ? "info"
                  : ""
    } label-inline font-weight-bold py-4`;
}

export function invoiceStatusLabelColor(status) {
  return `label label-lg label-light-${status === "paid"
    ? "primary"
    : status === "posted"
      ? "success"
      : status === "draft"
        ? "dark"
        : status === "payment-due"
          ? "warning"
          : status === "not-paid"
            ? "danger"
            : status === "voided"
              ? "dark"
              : status === "pending"
                ? "warning"
                : status === "future"
                  ? "info"
                  : status === "success"
                    ? "success" : ''
    } label-inline font-weight-bold py-4`;
}

export function getCurrencyClass(currency) {
  if (currency === "usd") {
    return <i className="fas fa-dollar-sign fa-xs"> </i>;
  } else if (currency === "gbp") {
    <i className="fas fa-pound-sign fa-xs"></i>;
  } else if (currency === "gbp") {
    <i className="fas fa-rupee-sign fa-xs"></i>;
  }
}

export function monthList(params) {
  return params === 1
    ? "Jan"
    : params === 2
      ? "Feb"
      : params === 3
        ? "March"
        : params === 4
          ? "April"
          : params === 5
            ? "May"
            : params === 6
              ? "June"
              : params === 7
                ? "July"
                : params === 8
                  ? "Aug"
                  : params === 9
                    ? "Sep"
                    : params === 10
                      ? "Oct"
                      : params === 11
                        ? "Nov"
                        : params === 12
                          ? "Dec"
                          : "";
}

// Valid JSON

export function getValidJsonStatus(text) {
  if (
    /^[\],:{}\s]*$/.test(
      text
        .replace(/\\["\\\/bfnrtu]/g, "@")
        .replace(
          /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
          "]"
        )
        .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export const statusOptions = [
  { value: null, label: "All" },
  { value: "active", label: "Active" },
  { value: "pending", label: "Pending" },
  { value: "draft", label: "Draft" },
  { value: "in-trial", label: "In Trial" },
  { value: "future", label: "Future" },
  { value: "paused", label: "Paused" },
];

export const invoiceStatus = [
  { value: null, label: "All" },
  { value: "paid", label: "Paid" },
  { value: "posted", label: "Posted" },
  { value: "payment-due", label: "Payment due" },
  { value: "not-paid", label: "Not paid" },
  { value: "voided", label: "Voided" },
  { value: "pending", label: "Pending" },
];

export function fullfillmentsStatusLabelColor(status) {
  return `label label-lg label-light-${status === "paid"
    ? "primary" : status === "delivered" ? "success" : status === "payment-due"
      ? "warning" : status === "cancelled" ? "danger" : status === "missed"
        ? "dark" : status === "pending" ? "warning" : status === "dispatched"
          ? "info" : ""} label-inline font-weight-bold py-4`;
}

export const fullfillmentsStatus = [
  { value: null, label: "All" },
  { value: "pending", label: "Pending" },
  { value: "dispatched", label: "Dispatched" },
  { value: "delivered", label: "Delivered" },
  { value: "missed", label: "Missed" },
  { value: "cancelled", label: "Cancelled" },
];

export const productAddonsStatus = [
  { value: null, label: "All" },
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
  { value: 2, label: "Archive" },
];

export const dealersStatus = [
  { value: null, label: "All" },
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

export const proRataInvoiceType = [
  {
    value: 'pro-rata', label: (
      <div className="d-flex justify-content-between">
        <div> Pro Rata</div>
        <div className="pro-rata-info">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">
              {'loren ipzumloren ipzumloren ipzumloren ipzumloren ipzum'}
            </Tooltip>}
          >
            <Eye size={24} weight="duotone" />
          </OverlayTrigger>
        </div>
      </div>
    )
  },
  {
    value: 'pro-rata_advance', label: (
      <div className="d-flex justify-content-between">
        <div>Pro Rata & advance</div>
        <div className="pro-rata-info">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">
              {'loren ipzumloren'}
            </Tooltip>}
          >
            <Eye size={24} weight="duotone" />
          </OverlayTrigger>
        </div>
      </div>
    )
  },
  {
    value: 'advance', label: (
      <div className="d-flex justify-content-between">
        <div> Advance </div>
        <div className="pro-rata-info">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">
              {'loren ipzumloren ipzumloren ipzum'}
            </Tooltip>}
          >
            <Eye size={24} weight="duotone" />
          </OverlayTrigger>
        </div>
      </div>
    )
  },
];


export const couponsStatus = [
  { value: null, label: "All" },
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

export const cancelRequestsStatus = [
  { value: null, label: "All" },
  { value: 0, label: "scheduled" },
  { value: 1, label: "in-progress" },
  { value: 2, label: "completed" },
  { value: 3, label: "cancelled" },
];

export function cancelRequestsLabelCss(status) {
  return `label label-lg label-light-${status === 1 ? "success" : status === 0 ? "info" : status === 2
    ? "warning" : status === 3 ? "danger" : null} label-inline`;
}

export function productAddonsLabelCss(status) {
  return `label label-lg label-light-${status === 1
    ? "primary"
    : status === 0
      ? "danger"
      : status === 2
        ? "warning"
        : ""
    } label-inline font-weight-bold`;
}

export function differenceObject(object, base) {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
}

export function handleProRateFieldShow(supplierId, supplierList, setIsProRateShow) {
  if (supplierList.length > 0) {
    let isProRataType = false;
    let isProRata = supplierList.filter((x) => x.id === supplierId);
    if (isProRata[0]?.is_pro_rata === 1) {
      setIsProRateShow(true);
      return isProRataType = true;
    } else {
      setIsProRateShow(false);
      isProRataType = false;
    }
    return isProRataType;
  }
}
