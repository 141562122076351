import create from "zustand";
import { persist } from "zustand/middleware"
export const useStore = create(persist((set) => ({
  token: null,
  isAuthorized: false,
  makeAuthorized: (loginData) =>
    set((state) => ({
      isAuthorized: (state.isAuthorized = loginData ? true : false),
      token: (state.token = loginData)
    }))
}),{
  name: "auth"
}));
