import { useQuery } from "react-query";
import API from "./../../../../api";

export default function useCustomerFilter(filter) {
  let key = filter;
  return useQuery(
    ["FilterCustomers", key],
    async () =>
      await API.get("GetCustomers", {
        "searchByOr[last_name]": key,
        "searchByOr[first_name]": key,
      }), {
    refetchOnWindowFocus: false, cacheTime: 2000, retry: false
  }
  );
}
