import {React} from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "../app/Routes";
import { I18nProvider } from "./i18n";
import {Helmet} from "react-helmet";
export default function App({ basename }) {

  return (
    <BrowserRouter basename={basename}>
      <I18nProvider>
        <Helmet>
          <title>{`${process.env.REACT_APP_NAME} - ${process.env.REACT_APP_VERSION}`}</title>
          <meta name="description" content={process.env.REACT_APP_NAME} data-react-helmet="true"></meta>
        </Helmet>
        <Routes />
      </I18nProvider>
    </BrowserRouter>
  );
}
