const EndPoints = {
  // login
  login: {
    url: "/admin/login",
  },
  me: {
    url: "/admin/me",
  },
  Auth: {
    url: "/admin",
  },
  ForgotPassword: {
    url: "/admin/password/forgot",
  },
  ResetPassword: {
    url: "/admin/password/reset",
  },
  RefreshToken: {
    url: "admin/refresh",
  },
  GetAllAgents: {
    url: "/users?",
  },
  // Customers
  GetCustomers: {
    url: "/admin/customers",
  },
  CustomerUrl: {
    url: "admin/customers",
  },
  CustomerChangePassword: {
    url: "admin/customers",
  },
  // Roles
  Role: {
    url: "admin/roles",
  },
  // Taxes
  Taxes: {
    url: "admin/taxes",
  },
  // Invoices
  Invoices: {
    url: "admin/invoices",
  },
  //Subscriptions
  Subscriptions: {
    url: "admin/subscriptions",
  },
  // Products
  GetProducts: {
    url: "admin/product-families",
  },
  // Plans
  Plans: {
    url: "admin/plans",
  },
  GetPlan: {
    url: "admin/plans",
  },
  GetAddons: {
    url: "admin/plan", // Needs to remove
  },
  Addons: {
    url: "admin/addons",
  },
  GetAddonsPrice: {
    url: "admin/addon/price",
  },
  GetCurrency: {
    url: "admin/currency",
  },
  GetPrices: {
    url: "admin/plan/price",
  },
  CreditNote: {
    url: "admin/credit-notes",
  },
  Charge: {
    url: "admin/charges",
  },
  Charges: {
    url: "admin/charges",
  },
  Suppliers: {
    url: "admin/suppliers",
  },
  ChargePrice: {
    url: "admin/charge/price",
  },
  ProductManagement: {
    url: "admin/subscription",
  },
  SupplierProducts: {
    url: "admin/supplier-products",
  },
  Coupons: {
    url: "admin/coupons",
  },
  Report: {
    url: "admin/report",
  },
  Currency: {
    url: "admin/currencies",
  },
  Agent: {
    url: "admin/agents",
  },
  Fullfilments: {
    url: "admin/fulfilments",
  },
  CancelRequest: {
    url: "admin/cancel-requests"
  },
  Permissions: {
    url: "admin/permissions"
  },
  Dealers: {
    url: "admin/dealers"
  },
  CostCenter: {
    url: "admin/cost-centers"
  },
  Jobs: {
    url: "admin/jobs"
  },
  Batches: {
    url: "admin/jobs/batches"
  },
  // Dynamic end point
  AdminEndString: {
    url: "admin",
  },
};

const config = {
  url: "https://aceapi.genapiconnect.com/api"
};

console.log(config,"ttt");

export { EndPoints, config };
