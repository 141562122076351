import React from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import useResetPassword from "./../../../hooks/services/login/useResetPassword";

const initialValues = {
  email: "",
  token: "",
  password: "",
  password_confirmation: "",
};

function ResetPassword() {
  const { mutateAsync } = useResetPassword();

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Required field"),
    token: Yup.string().required("Required field"),
    password: Yup.string().required("New Password is required"),
    password_confirmation: Yup.string()
      .required("Password confirmation is required")
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        const data = await mutateAsync(values);
        toast.success(data.message);
      } catch (error) {
        toast.error(error?.response?.data?.error?.common);
      }
    },
  });

  return (
    <>
      <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-10">
          <h3 className="font-size-h1">Reset Password ?</h3>
          <div className="text-muted font-weight-bold">
            Reset your account password
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <input
              type="email"
              placeholder="Email"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "email"
              )}`}
              name="email"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <input
              type="text"
              placeholder="Token"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "token"
              )}`}
              name="token"
              {...formik.getFieldProps("token")}
            />
            {formik.touched.token && formik.errors.token ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.token}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <input
              type="password"
              placeholder="New Password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <input
              type="password"
              placeholder="Confirm Password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password_confirmation"
              )}`}
              name="password_confirmation"
              {...formik.getFieldProps("password_confirmation")}
            />
            {formik.touched.password_confirmation &&
            formik.errors.password_confirmation ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.password_confirmation}
                </div>
              </div>
            ) : null}
          </div>

          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={formik.isSubmitting}
            >
              Reset
            </button>
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default ResetPassword;
