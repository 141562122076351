import React from "react";

export function Checkbox({ field, isSelected, onChange, name, id, value, label, size = 'sm' }) {
  return (
        <label className={`checkbox checkbox-${size} my-3`} >
        <input 
          type="checkbox" 
          id={id}
          name={name}
          value={value}
          checked={isSelected}
          onChange={onChange}
        />
        <span></span>
        <div className="ml-2" >{label}</div>
        </label>
  );
}
