export const columnStyle = {
    paddingBottom: "1em",
    borderWidth: "10px"
}

export const columnClasses = 'border-white align-middle'

export const wrapperClasses = 'table-responsive rounded'

export const headerClasses = 'bg-gray-800 text-white'

export const rowClasses = 'bg-gray-100'