import React from "react";

export function HeaderCheckbox({ isChecked, onChange, isDisabled, label, className='checkbox checkbox-sm checkbox-single' }) {
  return (
    <label className={className}>
      <input
        type="checkbox"
        disabled={isDisabled}
        checked={isChecked}
        onChange={onChange}
      />
      <span />
      <div className={isDisabled ? `ml-2 text-muted` : 'ml-2'}>{label}</div>
    </label>
  );
}
