import React from "react"
import { Formik, Form, Field } from "formik"
import { Input } from "./../../../partials/controls/forms/Input";
import * as Yup from "yup"
import { toast } from "react-toastify"
import { Button ,Modal } from "react-bootstrap"
import { useChangePassword } from "../../../hooks/services/Auth"
import showErrorToast from "../../../helpers/response-error" 
import { setFormikErrors } from "../../../helpers/setFormikErrors"

export default function ChangePassword({handleClose}) {

    const initialValues = { 
        old_password: '',
        new_password: '',
        new_password_confirmation: ''
    }
    const validationSchema = Yup.object().shape({
        old_password: Yup.string().required("Current password is required"),
        new_password: Yup.string().required("New password is required"),
        new_password_confirmation: Yup.string().required("New password confirmation is required")
    })
    const { mutateAsync } = useChangePassword()
    const submitChangePassword = async (values, formikHelpers) => {
            try {
                formikHelpers.setSubmitting(true)
                const result = await mutateAsync(values)
                toast.success(result.message)
                formikHelpers.setSubmitting(false)
                handleClose()
            } catch (error) {
                formikHelpers.setSubmitting(false)
                showErrorToast(error)
                if (error.response.data && error.response.data.error && typeof error.response.data.error === 'object') {
                    setFormikErrors(error.response.data.error, formikHelpers.setFieldError);
                }
            }
    }

    return (
        <>
        <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={submitChangePassword}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
                <Form className="form form-label-right">
                <Modal.Body>
                    <div className="form-group row">
                          <label htmlFor="old_password" className="col-sm-4 col-form-label">
                            Current Password
                            <span className="field-required">*</span>
                          </label>
                          <div className="col-sm-8">
                          <Field name="old_password" disabled={isSubmitting} placeholder="Old Password" type="password" component={Input} />
                          </div>
                    </div>
                    <div className="form-group row">
                          <label htmlFor="new_password" className="col-sm-4 col-form-label">
                            New Password
                            <span className="field-required">*</span>
                          </label>
                          <div className="col-sm-8">
                          <Field name="new_password" disabled={isSubmitting} placeholder="New Password" type="password" component={Input} />
                          </div>
                    </div>
                    <div className="form-group row">
                          <label htmlFor="new_password_confirmation"  className="col-sm-4 col-form-label">
                            Confirm Current Password
                            <span className="field-required">*</span>
                          </label>
                          <div className="col-sm-8">
                          <Field name="new_password_confirmation" disabled={isSubmitting} type="password" placeholder="Confirm New Password" component={Input} />
                          </div>
                    </div>
                    
                </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={isSubmitting} variant="secondary" onClick={(e)=> {
                            e.preventDefault()
                            handleClose()
                        }}>
                            Dismiss
                        </Button>
                        <Button disabled={isSubmitting} type="submit" variant="primary">
                            Change
                        </Button>
                    </Modal.Footer>
                </Form>
           )}
           </Formik>
           
            </>
    )
}