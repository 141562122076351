/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { AsideMenu } from "./aside-menu/AsideMenu";
import { Brand } from "../brand/Brand";
import { UserList, ArrowRight, ArrowLeft } from "phosphor-react";
import Pusher from 'pusher-js';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useLocation } from 'react-router';
const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, { cluster: 'eu', encrypted: true });


export function Aside(props) {

  const { updateExpand } = props;
  const location = useLocation();

  const uiService = useHtmlClassService();
  const [isExpand, setExpand] = useState(false);

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      asideSecondaryDisplay: objectPath.get(
        uiService.config,
        "aside.secondary.display"
      ),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      extrasSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      extrasNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      extrasQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      extrasQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      extrasUserDisplay: objectPath.get(
        uiService.config,
        "extras.user.display"
      ),
    };
  }, [uiService]);

  useEffect(() => {
    const channel = pusher.subscribe('notifications');
    channel.bind('new', data => {
      console.log(data)
    })

  }, []);

  useEffect(() => {
    if (location.pathname.slice('').includes('profile-overview')) {
      setExpand(true);
      updateExpand(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <div
        id="kt_aside"
        className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}
      >

        {layoutProps.asideSecondaryDisplay && (
          <>
            <div className="aside-secondary">
              <div className={`aside-workspace d-flex flex-column 
              ${isExpand ? 'w-70px' : 'side-nav-transition'}`}>
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="toggle-aside">Toggle Aside</Tooltip>}
                >
                  <span
                    className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm mb-10"
                    onClick={() => { setExpand(!isExpand); updateExpand(!isExpand) }}
                  >
                    {isExpand && <ArrowRight size={20} weight="duotone" />}
                    {!isExpand && <ArrowLeft size={20} weight="duotone" />}
                  </span>
                </OverlayTrigger>
                {!isExpand && <Brand />}
                <AsideMenu isExpand={isExpand} />
                <div className="aside-footer py-4 py-lg-10 d-flex justify-content-around">
                  <div className="btn btn-icon btn-clean btn-lg w-40px h-40px" id="kt_quick_user_toggle" data-placement="right" data-container="body" data-boundary="window">
                    <span className="symbol symbol-30 symbol-lg-40">
                      <UserList size={30} weight="duotone" />
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </>
        )}
      </div>
    </>
  );
}
