/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { useLocation, useHistory } from "react-router-dom";
// import { QuickActions } from "./components/QuickActions";
import { Button, Dropdown,Modal } from "react-bootstrap";
// import { BreadCrumbs } from "./components/BreadCrumbs";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../../layout/_core/MetronicSubheader";
import { useHtmlClassService } from "../../../layout/_core/MetronicLayout";
import Customers from "./../../../modules/Subscriptions/components/customerlist-modal/Customers";
import { ShoppingCart, UserCirclePlus, UserCircleGear } from "phosphor-react"
import CommonModal from './../../../partials/common-modal';
import ChangePassword from "../../../modules/Auth/components/change-password";


export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const histroy = useHistory();
  const subheader = useSubheader();
  const [customersModal, setCustomerModalShow] = useState(false);
  const [changePassModalShow, setChangePassModalShow] = useState(false);
  const handleClose = () => setCustomerModalShow(false);
  const handleShow = () => setCustomerModalShow(true);

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {

  }, [subheader]);

  return (
    <div
      id="kt_subheader"
      className={`subheader subheader-solid m-5 rounded p-5 ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap pr-0`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {/* begin::Mobile Toggle */}
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
          {/* end::Mobile Toggle */}

          {/* begin::Heading */}
          {/* <div className="d-flex align-items-baseline mr-5">
            <h2 className="subheader-title text-dark font-weight-bold my-2 mr-3">
              {subheader.title}
            </h2>

            <BreadCrumbs items={subheader.breadcrumbs} />
          </div> */}
          {/* end::Heading */}
        </div>
        {/* Info */}

        {/* Toolbar */}
        <div className="d-flex align-items-center flex-wrap">
          <Button
            size="sm"
            className="mr-5 font-weight-bolder"
            onClick={() => histroy.push("/customers/create")}
          >
            <UserCirclePlus size={24} weight="duotone" className="mr-2" />
            {` `}Create Customer
          </Button>

          <Button
            size="sm"
            className="mr-5 font-weight-bolder"
            onClick={handleShow}
          >
            <ShoppingCart size={24} weight="duotone" />
            {` `}Create Subscription
          </Button>
          <Modal
            show={customersModal}
            onHide={handleClose}
            backdrop="static"
          >
            <Customers onHide={handleClose} />
          </Modal>

          {/* <QuickActions /> */}

          {/* Button */}
          <Dropdown align="start">
            <Dropdown.Toggle bsPrefix="bs-" size="sm" variant="secondary" id="dropdown-user">
              <UserCircleGear size={24} weight="duotone" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="border">
              <Dropdown.Item onClick={() => setChangePassModalShow(true)}>
                Change Password
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => histroy.push("/logout")} >Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <CommonModal 
        show={changePassModalShow} 
        handleClose={() => setChangePassModalShow(false)} 
        title="Change Password" 
      >
        <ChangePassword handleClose={() => setChangePassModalShow(false)}/>
      </CommonModal>
    </div>
  );
}
