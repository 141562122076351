/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../helpers";
import { Menu } from "./Menu";
import { ArrowCircleRight } from "phosphor-react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export function AsideMenuList(props) {

  const { layoutProps, isExpand } = props;

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {Menu?.map((menu, i) => (
          <>
            {menu.hasOwnProperty("subMenu") && menu?.subMenu?.length ? (
              <li className={`menu-item menu-item-submenu ${getMenuItemActive(menu.subMenu[0].route, true)}`}
                key={`primary_nav_${i}`} id={`primary_nav_${i}`} >
                <OverlayTrigger placement="right" overlay={isExpand ? <Tooltip id="toggle-aside">{menu.name}</Tooltip> : <span></span>}>
                  <NavLink className="menu-link menu-toggle d-flex align-items-center" to="#" >
                    <span className="svg-icon menu-icon">{menu.icon}</span>
                    {!isExpand && <span className="menu-text font-weight-bold">{menu.name}</span>}
                    <ArrowCircleRight className="text-dark" size={20} weight="duotone" />
                  </NavLink>
                </OverlayTrigger>
                <div className="menu-submenu ">
                  <ul className="menu-subnav">
                    {menu.subMenu.map((subMenu, j) => (
                      <li
                        id={`sub_nav_${i}_${j}`}
                        key={`sub_nav_${i}_${j}`}
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                          subMenu.route,
                          false
                        )}`}
                      >
                        <OverlayTrigger placement="right" overlay={isExpand ? <Tooltip id="toggle-aside">{menu.name}</Tooltip> : <span></span>}>
                          <NavLink
                            className="menu-link menu-toggle"
                            to={subMenu.route}
                          >
                            <span className="svg-icon menu-icon">{subMenu.icon}</span>
                            {!isExpand && <span className="menu-text">{subMenu.name}</span>}
                          </NavLink>
                        </OverlayTrigger>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ) : (
              <li className={`menu-item ${getMenuItemActive(menu.route, false)}`} key={`primary_nav_${i}`} id={`primary_nav_${i}`}  >
                <OverlayTrigger placement="right" overlay={isExpand ? <Tooltip id="toggle-aside">{menu.name}</Tooltip> : <span></span>}>
                  <NavLink className="menu-link" to={menu.route} >
                    <span className="svg-icon menu-icon">{menu.icon}</span>
                    {!isExpand && <span className="menu-text font-weight-bold">{menu.name}</span>}
                  </NavLink>
                </OverlayTrigger>
              </li>
            )}
          </>
        ))}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
