import React, { useState, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import { MagnifyingGlass, UserCirclePlus, UserCircle, XCircle } from "phosphor-react";
import { Formik } from "formik";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import useCustomerFilter from "./../../../../hooks/services/Subscription/useCustomerFilter";
import debounce from "lodash/debounce";

export default function Customers({ onHide }) {
  const history = useHistory();
  let customerList = [];
  const [filter, setFilterValue] = useState({
    debouncedLog: "",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLog = useCallback(
    debounce((setFilterValue, values) => {
      setFilterValue((prevState) => ({
        ...prevState,
        debouncedLog: values.search,
      }));
    }, 500),
    []
  );

  const { data: customers, status: fetchStatus } = useCustomerFilter(
    filter.debouncedLog
  );

  if (fetchStatus === "success" && customers) {
    customerList = customers.data.customers;
  }

  const redirectToCreate = (customerId) => {
    history.push(`/subscriptions/new/${customerId}`);
    onHide();
  };

  return (
    <div>
      <Modal.Header>
        <Modal.Title>
          Pick a customer <br />
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            Choose the customer you’re creating the subscription for.
          </span>
        </Modal.Title>
        <XCircle className="cursor-pointer" size={32} onClick={onHide} weight="duotone" />
      </Modal.Header>
      <Modal.Body>
        <div className="input-icon h-40px">
          <Formik
            initialValues={{
              search: "",
            }}
            onSubmit={(values) => {
              debouncedLog(setFilterValue, values);
            }}
          >
            {({
              values,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
            }) => (
              <form>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control form-control-lg form-control-solid h-40px"
                  placeholder="Search"
                  id="generalSearch"
                  onBlur={handleBlur}
                  value={values.search}
                  name="search"
                  onChange={(e) => {
                    setFieldValue("search", e.target.value);
                    handleSubmit();
                  }}
                />
                <span>
                  <MagnifyingGlass size={24} weight="duotone" />
                </span>
              </form>
            )}
          </Formik>
        </div>

        <hr></hr>
        {fetchStatus === "loading" &&
          [1, 2, 3].map((num) => (
            <Skeleton className="mb-10" key={num} width={450} height={20} />
          ))}
        <div className="modal-height">
          {fetchStatus === "success" && (
            <ListGroup variant="flush">
              {customerList.map((customer) => (
                <ListGroup.Item
                  action
                  key={customer.id}
                  onClick={() => redirectToCreate(customer.id)}
                >
                  <div className="list-item">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-40 symbol-light mr-4">
                        <UserCircle size={40} weight="duotone" />
                      </div>
                      <div className="d-flex flex-column flex-grow-1 mr-2">
                        <h5 className="text-dark font-weight-bolder m-0 p-0">
                          {customer.first_name} {customer.last_name}
                        </h5>
                        <span className="text-dark-50 font-size-sm mt-1">
                          {customer.email}
                        </span>
                      </div>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
          {fetchStatus === "success" &&
            customerList.length === 0 &&
            `No customer matching the filter "${filter.debouncedLog}"`}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link to="/customers/create" className="m-auto" onClick={onHide}>
          <UserCirclePlus size={24} weight="duotone" className="mr-3" />
          Create Customer
        </Link>
      </Modal.Footer>
    </div>
  );
}
