import axios from "axios";
import { config, EndPoints } from "./config";
import Swal from 'sweetalert2'

const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;

const instance = axios.create({
  baseURL: config.url,
  timeout: TIMEOUT,
});


instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    Swal.fire({
      title: "Session Expired",
      text: "Your session has expired. Would you like to be redirected to the login page?",
      showDenyButton: true,
      denyButtonText: 'No',
      confirmButtonText: "Yes",
      focusConfirm: true
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear(); // Clear accessToken from localStorage
        window.location = '/logout';
        return Promise.reject(error);
      }
    });
  } else {
    return Promise.reject(error);
  }
});

instance.interceptors.request.use(function (config) {
  const headers = {
    "content-type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  config.headers = headers;
  return config;
});

const API = {
  get: async (key, params = {}, id, endString = "") => {
    const url =
      EndPoints[key].url +
      (id ? `/${id}` : "") +
      (endString ? `/${endString}` : "");
    async function apiCall() {
      return instance.get(url, {
        params
      });
    }
    const getData = await apiCall();
    return getData?.data;
  },

  post: async (key, data = {}, id = "", endString = "") => {
    const url =
      EndPoints[key].url +
      (id ? `/${id}` : "") +
      (endString ? `/${endString}` : "");
    async function apiCall() {
      return instance({
        method: "POST",
        url: url,
        data: data
      });
    }
    const postData = await apiCall();
    return postData?.data;
  },

  put: async (key, data = {}, id = "", endString = "") => {
    const url = EndPoints[key].url + (id ? `/${id}` : "") + (endString ? `/${endString}` : "");
    async function apiCall() {
      return instance({
        method: "PUT",
        url: url,
        data: data
      });
    }

    const postData = await apiCall();
    return postData?.data;
  },

  patch: async (key, data = {}, id = "", endString = "") => {
    const url =
      EndPoints[key].url +
      (id ? `/${id}` : "") +
      (endString ? `/${endString}` : "");
    async function apiCall() {
      return instance({
        method: "PATCH",
        url: url,
        data: data
      });
    }
    const patchData = await apiCall();
    return patchData?.data;
  },

  delete: async (key, data = {}, id = "", endString = "") => {
    const url = EndPoints[key].url + (id ? `/${id}` : "") + (endString ? `/${endString}` : "");
    async function apiCall() {
      return instance({
        method: "DELETE",
        url: url,
        data: data
      });
    }
    const deleteData = await apiCall();
    return deleteData?.data;
  },

  customGet: async (key, params = {}, id, endString = "") => {
    const url =
      EndPoints[key].url +
      (id ? `/${id}` : "") +
      (endString ? `/${endString}` : "");
    async function getData() {
      return instance.get(url, {
        params
      });
    }
    const getdata = await getData();
    return getdata;
  },

  download: async (key, params = {}, id, endString = "") => {
    const url =
      EndPoints[key].url +
      (id ? `/${id}` : "") +
      (endString ? `/${endString}` : "");
    async function getData() {
      return instance.get(url, {
        params,
        responseType: 'blob'
      });
    }
    const getdata = await getData();
    return getdata;
  },
};

export default API;
