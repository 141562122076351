/* eslint-disable jsx-a11y/anchor-is-valid */
import  { React, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { XCircle, UserGear, At } from "phosphor-react";
import { useAdminStore } from "../../../../store/admin";
export function QuickUser() {
  const history = useHistory();
  const { admin  } = useAdminStore();
  useEffect(() => {
    useAdminStore.getState().getAdmin()
  }, []);

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          <small className="text-dark font-size-md ml-2">{admin?.name}</small>
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <XCircle size={24} weight="duotone" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex flex-column align-items-center mt-5">
          <div className="symbol symbol-100">
            <div className="symbol-label">
              <UserGear size={48} weight="duotone" />
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="py-5">
              <At size={24} weight="duotone" /> {admin?.email}
            </div>
            <button
              className="btn btn-light-primary btn-bold"
              onClick={logoutClick}
            >
              Sign out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
