import React from 'react'
import { Modal } from 'react-bootstrap'
import { XCircle } from 'phosphor-react'

export default function CommonModal({children,show, handleClose, title,size='md'}) {

    return (
        <Modal show={show} onHide={handleClose} size={size} backdrop="static">
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <XCircle className="cursor-pointer" weight="duotone" size={32} onClick={handleClose} />
            </Modal.Header>
            {children}
        </Modal>
    )
}
