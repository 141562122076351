import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import BasePage from "./BasePage";
import ErrorsPage from "./modules/ErrorsExamples/ErrorPage1";
import { Logout, AuthPage } from "./modules/Auth";
import { Layout } from "./../app/layout";
import { useStore } from "./store";
// import InvoiceExternalPage from "./modules/InvoicesCreditNotes/pages/InvoiceExternalPage";

export function Routes() {
  const { isAuthorized } = useStore();
  const PrivateRoute = ({ component: Component, isUserLoggedIn, ...props }) => {
    return (
      <>
        <Route
          {...props}
          render={props => {
            if (isAuthorized) {
              return <Component {...props} isUserLoggedIn={isAuthorized} />;
            } else {
              return <Redirect to="/auth/login" />;
            }
          }}
        />
      </>
    );
  };
  return (
    <Switch>
      {!isAuthorized ? (
        <>
          <Route>
            <AuthPage />
          </Route>
        </>
      ) : (
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/logout" component={Logout} />
      <Route path="/not-found" component={ErrorsPage} />
      <Layout>
        <PrivateRoute component={BasePage} />
      </Layout>
      
    </Switch>
  );
}
