import {
  ShoppingBag,
  ShieldCheckered,
  Receipt,
  ShoppingCart,
  UserCircle,
  Sliders,
  Package,
  MagicWand,
  StarHalf,
  Wallet,
  QrCode,
  RocketLaunch,
  CirclesFour,
  IdentificationBadge,
  UsersFour,
  Truck,
  Handshake,
  FileX,
  Gear,
  List,
  LinkedinLogo
} from "phosphor-react";

export const Menu = [
  {
    name: "Dashboard",
    icon: <CirclesFour size={24} weight="duotone" className="mr-2" />,
    isOpen: false,
    route: "/",
  },
  {
    name: "Subscriptions",
    icon: <ShoppingCart size={24} weight="duotone" className="mr-2" />,
    isOpen: false,
    route: "/subscriptions",
  },
  {
    name: "Customers",
    icon: <UserCircle size={24} weight="duotone" className="mr-2" />,
    isOpen: false,
    route: "/customers",
  },
  {
    name: "Orders",
    icon: <Package size={24} weight="duotone" className="mr-2" />,
    isOpen: false,
    route: "/orders",
  },
  {
    name: "Fulfilments",
    icon: <Truck size={24} weight="duotone" className="mr-2" />,
    isOpen: false,
    route: "/fullfilments",
  },
  {
    name: "Cancellation Requests",
    icon: <FileX size={24} weight="duotone" className="mr-2" />,
    isOpen: false,
    route: "/cancel-requests",
  },
  {
    name: "Products & Addons",
    icon: <ShoppingBag size={24} weight="duotone" className="mr-2" />,
    route: "/pro",
    isOpen: false,
    subMenu: [
      {
        name: "Plans",
        icon: <RocketLaunch size={24} weight="duotone" className="mr-2" />,
        route: "/products-addons/plans",
      },
      {
        name: "Addons",
        icon: <StarHalf size={24} weight="duotone" className="mr-2" />,
        route: "/products-addons/addons",
      },
      {
        name: "Charges",
        icon: <Wallet size={24} weight="duotone" className="mr-2" />,
        route: "/products-addons/charges",
      },
    ],
  },
  {
    name: "Invoices & Credit Notes",
    icon: <Receipt size={24} weight="duotone" className="mr-2" />,
    isOpen: false,
    route: "/invoices",
  },
  {
    name: "System & Configurations",
    icon: <Sliders size={24} weight="duotone" className="mr-2" />,
    route: "/system-configuration/roles",
    isOpen: false,
    subMenu: [
      {
        name: "Suppliers",
        icon: (
          <IdentificationBadge size={24} weight="duotone" className="mr-2" />
        ),
        route: "/system-configuration/suppliers",
      },
      {
        name: "Products",
        icon: <QrCode size={24} weight="duotone" className="mr-2" />,
        route: "/system-configuration/products",
      },
      {
        name: "Coupons",
        icon: <MagicWand size={24} weight="duotone" className="mr-2" />,
        route: "/system-configuration/coupons",
      },
      {
        name: "Team",
        icon: <UsersFour size={24} weight="duotone" className="mr-2" />,
        route: "/system-configuration/agents",
      },
      {
        name: "Roles",
        icon: <ShieldCheckered size={24} weight="duotone" className="mr-2" />,
        route: "/system-configuration/roles",
      },
      {
        name: "Dealers",
        icon: <Handshake size={32} weight="duotone" className="mr-2" />,
        route: "/system-configuration/dealers",
      },
      {
        name: "Jobs",
        icon: <LinkedinLogo size={32} weight="duotone" className="mr-2" />,
        route: "/system-configuration/jobs",
      },
      {
        name: "Batches",
        icon: <List size={32} weight="duotone" className="mr-2" />,
        route: "/system-configuration/batches",
      },
      {
        name: "Configurations",
        icon: <Gear size={32} weight="duotone" className="mr-2" />,
        route: "/system-configuration/configurations",
      },
    ],
  },
];
