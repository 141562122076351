import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../layout";
import { useStore } from "../../../store";

function Logout() {
  const { isAuthorized, makeAuthorized } = useStore();
  useEffect(() => {
    makeAuthorized(null);
    localStorage.clear();
  });

  return (
    <div>
      {isAuthorized ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />}
    </div>
  );
}

export default Logout;
