import React, { Suspense, lazy } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../app/layout";

const SystemConfiguration = lazy(() => import("./modules/SystemConfiguration"));
const Invoices = lazy(() => import("./modules/InvoicesCreditNotes"));
const Customers = lazy(() => import("./modules/Customers"));
const Subscriptions = lazy(() => import("./modules/Subscriptions"));
const Plans = lazy(() => import("./modules/Plans"));
const Addons = lazy(() => import("./modules/Addons"));
const Charges = lazy(() => import("./modules/Charges"));
const Orders = lazy(() => import("./modules/Orders"));
const Fullfilments = lazy(() => import("./modules/Fullfilments"));
const Dashboard = lazy(() => import("./modules/Dashboard"));
const CancelRequests = lazy(() => import("./modules/CancelationRequest"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute path="/products-addons/plans" component={Plans} />
        <ContentRoute path="/system-configuration" component={SystemConfiguration} />
        <ContentRoute path="/customers" component={Customers} />
        <ContentRoute path="/invoices" component={Invoices} />
        <ContentRoute path="/subscriptions" component={Subscriptions} />
        <ContentRoute path="/products-addons/addons" component={Addons} />
        <ContentRoute path="/products-addons/charges" component={Charges} />
        <ContentRoute path="/orders" component={Orders} />
        <ContentRoute path="/fullfilments" component={Fullfilments} />
        <ContentRoute path="/cancel-requests" component={CancelRequests} />
        <ContentRoute path="/" exact component={Dashboard} />
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  );
}
