import create from "zustand";
import API from "../../api";

export const useAdminStore = create(set => ({
  admin: null,
  getAdmin: async() => {
    try{
      const response = await  API.get("me")
      set({ admin: response.data })
    }catch(e){
      set({ admin: null })
    }
  }
}));
